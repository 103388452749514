<template>
<footer class="footer pt-3 pb-0 mb-0 bg-light text-dark" v-if="canShow">
    <div class="container">
      <div class="row">

        <div class="col-md-3 col-12 text-center mb-3">
          <img src="/logo.png" alt="Logo Carreiro Móveis" class="footer-logo" />
          <p>
            <!-- <a :href="'mailto:'+'moreiramoveiseletro@gmail.com'" class="text-muted text-decoration-none">
              <i class="fa fa-envelope"></i> 
              moreiramoveiseletro@gmail.com</a><br /> -->
            <a :href="'https://www.instagram.com/carreiromoveis/'" target="_blank" class="text-muted text-decoration-none">
              <i class="fab fa-instagram"></i> carreiromoveis
            </a><br />
            <a :href="'https://www.facebook.com/CarreiroMoveis'" target="_blank" class="text-muted text-decoration-none">
              <i class="fab fa-facebook"></i> CarreiroMoveis
            </a><br />    
            <a :href="'tel:+556634240086'" target="_blank" class="text-muted text-decoration-none">
              <i class="bi bi-telephone"></i> (66)3424-0086
            </a><br />
            <a :href="'tel:+556634211509'" target="_blank" class="text-muted text-decoration-none">
              <i class="bi bi-telephone"></i> (66)3421-1509
            </a>                        
          </p>
        </div>

        <div class="col-md-3 col-12 text-center mb-3">
          <h5 class="text-danger"><strong>Formas de pagamento</strong></h5>
          <div class="payment-icons align-items-center">
            <div class="payment-item w-50">
              <i class="fas fa-credit-card"></i>
              <span>Débito/Crédito</span>
            </div>
            <div class="payment-item w-50">
              <i class="fas fa-barcode"></i>
              <span>Boleto Bancário</span>
            </div>
            <div class="payment-item w-50">
              <i class="fas fa-qrcode"></i>
              <span>PIX</span>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-12 text-center mb-3">
          <h5 class="text-danger"><strong>Selos de segurança</strong></h5>
          <img src="/stamp_encryptssl.png" alt="Selos de Segurança" class="img-fluid" />
        </div>

        <div class="col-md-3 col-12 text-center mb-3">
          <h5 class="text-danger"><strong>Formas de envio</strong></h5>
          <h4><i class="fas fa-shipping-fast text-info"></i></h4>
          <p class="text-muted">Entrega grátis para Rondonópolis</p>
        </div>
      </div>

      <hr class="m-0"/>
      <div class="text-center mt-0 mb-2">
        <small class="text-muted">
          Campos & Anjos Ltda Epp © Todos os direitos reservados. {{currentYear}}<br />
          Rua Dom Pedro II, 474 Centro, Rondonópolis-MT - CEP 78700-220.<br />
          Desenvolvimento:
          <a href="https://dataexpert.com.br" target="_blank">
            <img src="/dataexpert.png" alt="Data Expert Tecnologia" class="data-expert-logo">
          </a>
        </small>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComp',
  components: {
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      canShow: true
    };
  },
  watch: {
    '$route'(to) {
      this.canShow = !['/profile', '/users'].includes(to.path);
    }
  },
  methods: {
    showNotification(type, message, duration = 3000) {
      this.$refs.notificationBox.triggerNotification(type, message, duration);
    },
  },
};
</script>

<style scoped>
footer {
  background-color: #f8f9fa;
  border-top: 1px solid #e7e7e7;
}

.footer-logo {
  max-width: 150px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.payment-icons {
  display: flex;
  flex-direction: column; /* Ícones em coluna */
  gap: 10px; /* Espaço entre os itens */
}

.payment-item {
  display: flex;
  align-items: center; /* Centraliza ícone e texto verticalmente */
  gap: 8px; /* Espaço entre o ícone e o texto */
}

.payment-item i {
  font-size: 1.4rem; /* Tamanho dos ícones */
  color: #8f8888; /* Cor do ícone */
}

.payment-item span {
  font-size: 0.9rem; /* Tamanho do texto */
  color: #333; /* Cor do texto */
}

.data-expert-logo {
  width: auto;
  height: 15px;
}

@media (max-width: 576px) {
  .footer-logo {
    max-width: 100px;
  }

  .data-expert-logo {
    max-width: 90px;
    /* Ajuste para telas pequenas */
  }
}
</style>