export function linkWhatsApp(productId = 0, productDescription = '') {
    const phoneNumber = '5566984012753';
    const message = `Olá, gostaria de mais informações sobre o produto: Cód.: [*${productId}*] - ${productDescription}`;
    const link = `https://wa.me/${phoneNumber}?text=${message}`;

    return link;    
}

export function whatsApp() {
    const phoneNumber = '5566984012753';
    const link = `https://wa.me/${phoneNumber}`;
    return link;    
}