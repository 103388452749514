<template>
  <nav class="navbar navbar-dark navbar-expand-md py-0 my-0 bg-info">
    <div class="container">
      <button class="navbar-toggler ms-auto my-1" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarMenus" aria-controls="navbarMenus" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarMenus">

        <ul class="navbar-nav my-0 py-0 row">               
          <div class="col-auto d-flex flex-column" v-for="section in sections" :key="'section-' + section.section_id">
            <li class="nav-item d-flex justify-content-start">
              
              <router-link class="nav-link active" :to="`/search?t=[${section.section_id}]`" @click="selectSection(section)">
                {{ section.section_name }}</router-link>
              <a class="nav-link dropdown-toggle  p-0 m-0 my-auto lead" href="#" id="navbarDropdownSection"
                role="button" data-bs-toggle="dropdown" aria-expanded="false">
              </a>

              <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdownSection">
                <li v-for="group in getCombinedGroup(section.section_id)" :key="'group-' + group.id" class="d-flex flex-column">
                  
                  <router-link class="dropdown-item" :to="`/search?t=[${section.section_id},${group.id}]`" @click="selectGroup(section, group)">
                    <strong>{{ group.name }}</strong></router-link>
                  <router-link v-for="sub in getCombinedSubGroup(section.section_id, group.id)" :key="'subgroup-' + sub.id"
                      class="dropdown-item m-0 py-0 ps-5" style="text-decoration: none;"
                        :to="`/search?t=[${section.section_id},${group.id},${sub.id}]`" @click="selectSubgroup(section, group, sub)">
                        <strong class="text-primary lead"><b>·</b></strong>&nbsp;{{sub.name }}</router-link>
                  
                  <hr class="dropdown-divider" v-if="getCombinedGroup(section.section_id).length < -1">
                </li>
              </ul>

            </li>
          </div>

        </ul>

      </div>
    </div>
  </nav>

  <nav class="container" v-if="selectedSection || selectedGroup || selectedSubgroup">
    <ol class="breadcrumb mb-0 pb-0" style="background-color: #F7F7F7;">
      <li class="breadcrumb-item mb-0 pb-0" v-if="selectedSection">
        <strong v-if="!selectedGroup" class="text-primary">{{ selectedSection.section_name }}</strong>
        <router-link v-else :to="`/search?t=[${selectedSection.tags}]`" @click="resetToSection" class="mb-0 pb-0">
          {{ selectedSection.section_name }}
        </router-link>
      </li>
      <li class="breadcrumb-item mb-0 pb-0" v-if="selectedGroup">
        <strong v-if="!selectedSubgroup" class="text-primary">{{ selectedGroup.name }}</strong>
        <router-link  v-else :to="`/search?t=[${selectedGroup.tags}]`" @click="resetToGroup" class="mb-0 pb-0">
          {{ selectedGroup.name }}
        </router-link>
      </li>
      <li class="breadcrumb-item mb-0 pb-0" v-if="selectedSubgroup">
        <strong class="text-primary">{{ selectedSubgroup.name }}</strong>
      </li>
    </ol>
  </nav>

</template>

<script>
export default {
  data() {
    return {
      sections: [],
      groups: [],
      subgroups: [],
      selectedSection: null,
      selectedGroup: null,
      selectedSubgroup: null
    };
  },
  methods: {
    async fetchMenuItems() {
      try {
        const response = await this.$axios.get('/groups/tree');
        const items = response.data.items;

        this.sections = items.reduce((acc, item) => {
          if (!acc.some(sec => sec.section_id === item.section_id)) {
            acc.push({ section_id: item.section_id, section_name: item.section_name });
          }
          return acc;
        }, []);

        this.groups = items.reduce((acc, item) => {
          if (!acc.some(grp => grp.group_id === item.group_id)) {
            acc.push({ id: item.group_id, name: item.group_name, section_id: item.section_id, type: 'group' });
          }
          return acc;
        }, []);

        this.subgroups = items.reduce((acc, item) => {
          if (!acc.some(subgrp => subgrp.subgroup_id === item.subgroup_id)) {
            acc.push({
              id: item.subgroup_id, name: item.subgroup_name,
              section_id: item.section_id, group_id: item.group_id, type: 'subgroup'
            });
          }
          return acc;
        }, []);

      } catch (err) {
        console.error(err);
      }
    },

    getCombinedGroup(section_id) {
      const groupItems = this.groups.filter(group => group.section_id === section_id);

      const uniqueItems = groupItems.reduce((acc, current) => {
        if (!acc.some(item => item.name === current.name)) {
          acc.push(current);
        }
        return acc;
      }, []);

      return uniqueItems;
    },

    getCombinedSubGroup(section_id, group_id) {
      const subgroupItems = this.subgroups.filter(subgroup =>
        subgroup.section_id === section_id && subgroup.group_id === group_id
      );

      const uniqueItems = subgroupItems.reduce((acc, current) => {
        if (!acc.some(item => item.name === current.name) && current.name !== 'GERAL') {
          acc.push(current);
        }
        return acc;
      }, []);

      return uniqueItems;
    },

    selectSection(section) {
      this.selectedSection = {
        section_id: section.section_id,
        section_name: section.section_name,
        tags: [section.section_id]
      };
      this.selectedGroup = null;
      this.selectedSubgroup = null;
    },

    selectGroup(section, group) {
      this.selectedSection = {
        section_id: section.section_id,
        section_name: section.section_name,
        tags: [section.section_id]
      };
      this.selectedGroup = {
        id: group.id,
        name: group.name,
        tags: [section.section_id, group.id]
      };
      this.selectedSubgroup = null;

    },
    
    selectSubgroup(section, group, sub) {
      this.selectedSection = {
        section_id: section.section_id,
        section_name: section.section_name,
        tags: [section.section_id]
      };
      this.selectedGroup = {
        id: group.id,
        name: group.name,
        tags: [section.section_id, group.id]
      };
      this.selectedSubgroup = {
        id: sub.id,
        name: sub.name,
        tags: [section.section_id, group.id, sub.id]
      };
    },

    resetToSection() {
      this.selectedGroup = null;
      this.selectedSubgroup = null;
    },
    resetToGroup() {
      this.selectedSubgroup = null;
    }
  },
  created() {
    this.fetchMenuItems();
  }
};
</script>

<style scoped>
.dropdown-menu .dropdown-submenu {
  position: relative;
}

.dropdown-menu .dropdown-submenu > .dropdown-menu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 1000;
}

.dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
</style>