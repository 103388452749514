<template>
  <a v-if="showButton" href="#" class="top-float" aria-label="Voltar ao Topo" @click.prevent="scrollToTop">
    <i class="bi bi-arrow-up"></i>
  </a>
</template>

<script>
export default {
  data() {
    return {
      showButton: false, // Controla a visibilidade do botão
    };
  },
  methods: {
    // Função para monitorar o scroll da página
    handleScroll() {
      this.showButton = window.scrollY > 100;
    },
    // Função para rolar a página para o topo
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  mounted() {
    // Adiciona o evento de scroll
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    // Remove o evento de scroll
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.top-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 135px;
  /* Distância da parte inferior */
  right: 20px;
  /* Distância da lateral direita */
  background-color: #eb673e75;
  /* Cor padrão */
  color: white;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  z-index: 1000;
  transition: transform 0.3s, box-shadow 0.3s;
}

.top-float:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  text-decoration: none;
  /* Remove o sublinhado */
  color: white;
  /* Garante que o ícone fique branco */
}
</style>