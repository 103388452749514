<template>
  <div class="navbar navbar-light bg-white sticky-top py-1 shadow">
    <div class="container">
      <div class="row w-100 align-items-center">
        <div class="col-md-3 text-center text-md-left text-sm-left mb-2 mb-md-0">
          <a href="/" class="navbar-brand">
            <img src="/logo.png" alt="Logo" class="img-fluid">
          </a>
        </div>

        <div class="col-md-9 d-flex align-items-end justify-content-end">
          <ul class="navbar-nav ml-auto" v-if="!getIsUser()">
            <li class="nav-item">
              <router-link class="nav-link text-info text-decoration-none" to="/users" active-class="active">
                <i class="fa fa-users"></i>&nbsp;Usuários</router-link>
            </li>
          </ul>

          <a class="my-auto nav-link text-info text-decoration-none" href="#" @click="showProfile()" v-if="getLogged()"><i
              class="fa fa-user-alt"></i>&nbsp;{{ this.getNameUser() }} </a>&nbsp;

          <a class="btn btn-outline-info my-auto" href="/login" v-if="getLogged()">Sair</a>
          <a class="btn btn-outline-info my-auto" href="/login" v-if="!getLogged()">
            <i class="fa fa-user-alt"></i>&nbsp;Acessar
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { getDecodedToken, isLogged, renewToken } from '@/services/tokenService';

export default {
  name: 'NavBarUser',
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    login() {
      this.$router.push('/login');
    },

    showProfile() {
      this.$router.push('/profile');
    },

    getNameUser() {
      return getDecodedToken().name ?? '';
    },

    getLogged() {
      return isLogged();
    },

    getIsUser() {
      return ['USER', 'SALES'].includes(getDecodedToken().role);
    }
  },
  mounted() {
    renewToken();
  }
};
</script>

<style scoped>
.navbar-brand img {
  max-height: 50px;
}

@media (min-width: 768px) {
  .navbar-toggler {
    margin-left: 15px;
  }
}
</style>
