<template>
    <a :href="link" target="_blank" class="whatsapp-float" aria-label="Abrir chat no WhatsApp">
        <i class="bi bi-whatsapp"></i>
    </a>
</template>

<script>
import { whatsApp } from '@/services/contactService';
export default {
    data() {
        return {
            link: whatsApp()
        };
    },
};
</script>

<style scoped>
.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 60px;
    /* Distância da parte inferior */
    right: 20px;
    /* Distância da lateral direita */
    background-color: #25d366;
    /* Cor padrão do WhatsApp */
    color: white;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    z-index: 1000;
    transition: transform 0.3s, box-shadow 0.3s;
}

.whatsapp-float:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    /* Remove o sublinhado */
    color: white;
    /* Garante que o ícone fique branco */
}
</style>