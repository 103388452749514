<template>
  <div id="app">
    <router-view></router-view>
    <NotificationBox ref="notificationBox" />
    <TopFloatingBtn />
    <WppFloatingBtn />
    <FooterComp />
  </div>
</template>

<script>
import FooterComp from './components/FooterComp.vue';
import NotificationBox from './components/NotificationBox.vue';
import TopFloatingBtn from './components/TopFloatingBtn.vue';
import WppFloatingBtn from './components/WppFloatingBtn.vue';


export default {
  name: 'App',
  components: {
    NotificationBox,
    TopFloatingBtn,
    WppFloatingBtn,
    FooterComp
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    showNotification(type, message, duration = 3000) {
      this.$refs.notificationBox.triggerNotification(type, message, duration);
    },
  },
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgba(240, 239, 239, 0.315);
}

router-view {
  flex: 1;
  /* Faz o conteúdo do router ocupar o espaço disponível */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
</style>