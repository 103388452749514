<template>
  <div class="navbar navbar-light bg-white sticky-top py-1 shadow">
    <div class="container">
      <div class="row w-100 align-items-center">
        <div class="col-md-3 text-center text-md-left text-sm-left mb-2 mb-md-0">
          <a href="/" class="navbar-brand">
            <img src="/logo.png" alt="Logo" class="img-fluid">
          </a>
        </div>

        <div class="col-md-5 w-100 d-flex align-items-end justify-content-end justify-content-md-end">
          <form class="d-flex mb-0 w-100 w-md-auto w-sm-auto" @submit.prevent="handleSearch">
            <div class="input-group">
              <input
                v-model="searchQuery"
                class="form-control"
                type="search"
                placeholder="Buscar produtos"
                aria-label="Search"
              >
              <button class="btn btn-outline-info" type="submit">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </form>
        </div>

        <div class="col-md-4 d-flex align-items-end justify-content-end">
          <a class="my-auto nav-link text-info text-decoration-none" href="#" @click="showProfile()" v-if="getLogged()"><i class="fa fa-user-alt"></i>&nbsp;{{ this.getNameUser() }} </a>&nbsp;
       
          <a class="btn btn-outline-info my-auto" href="/login" v-if="getLogged()">Sair</a>
          <a class="btn btn-outline-info my-auto" href="/login" v-if="!getLogged()">
            <i class="fa fa-user-alt"></i>&nbsp;Acessar
          </a>        
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { getDecodedToken, isLogged, renewToken } from '@/services/tokenService';

export default {
  name: 'NavBar',
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    handleSearch() {
      if (this.searchQuery.trim() !== '') {
        this.$router.push({ path: '/search', query: { qr: this.searchQuery } });
      }
    },

    login() {
      this.$router.push('/login');
    },

    showProfile() {
      this.$router.push('/profile');
    },

    getNameUser() {
      return getDecodedToken().name ?? '';
    },

    getLogged() {
      return isLogged();
    }
  },
  mounted() {
    renewToken();
  }

};
</script>

<style scoped>
.navbar-brand img {
  max-height: 50px;
}

@media (min-width: 768px) {
  .navbar-toggler {
    margin-left: 15px;
  }
}
</style>
